import { Image, Spinner } from '@heroui/react';
import { t } from 'i18next';
import CountUp from 'react-countup';
import useTotalWinnings from '@gg/api/query/home/useTotalWinnings'
import LoginButton from '../../navbar/LoginButton';
import { hero_games_logos } from '../constants';


export default function Hero() {
  return (
    <section
      className="flex flex-col items-center justify-center gap-4 bg-cover bg-center bg-no-repeat px-2 py-8 text-white lg:py-14"
      style={{
        backgroundImage:
          "url('/images/new-homepage/backgrounds/Background Image Top.webp')",
      }}
    >
      <HeroTitle />
      <Countup />
      <LoginButton classNames="lg:w-74 xl:w-80" />
      <HeroGames />
    </section>
  )
}

const HeroTitle = () => {
  return (
    <h1 className="flex flex-col items-center gap-2 lg:gap-4">
      <span className="text-5xl font-semibold md:text-6xl xl:text-7xl">
        {t('play_win')}
      </span>
      <span className="text-center text-lg font-medium md:text-3xl xl:text-5xl">
        {t('Any Time • Any Place • Every Minute')}
      </span>
    </h1>
  )
}

const HeroGames = () => {
  return (
    <div className="flex flex-wrap items-center justify-center gap-2">
      {hero_games_logos.map(({ img, name }) => (
        <Image
          key={name}
          src={img}
          alt={name}
          className="w-[110px] lg:w-[230px]"
          // disableAnimation
        />
      ))}
    </div>
  )
}

const Countup = () => {
  return (
    <h2 className="font-display flex flex-col items-center">
      <span className="text-base font-bold lg:text-xl">
        {t('Total winnings')}{' '}
      </span>
      <span
        className="text-green-success text-5xl font-bold md:text-6xl xl:text-7xl"
        dir="ltr"
      >
        <Counter />
      </span>

      <span className="text-base font-bold lg:text-xl">
        {t('Join and start accumulating')}
      </span>
    </h2>
  )
}

const Counter = () => {
  const { data: totalWinnings, isPending } = useTotalWinnings()
  const endNumber = Number(totalWinnings?.data)
  const startNumber =
    endNumber.toString().length > 3
      ? Math.pow(10, endNumber.toString().length - 1)
      : 100

  if (isPending) return <Spinner />

  return (
    <CountUp
      start={startNumber}
      end={endNumber}
      duration={7}
      delay={0}
      decimal=","
      prefix="$ "
    >
      {({ countUpRef }) => (
        <div>
          <span ref={countUpRef} />
        </div>
      )}
    </CountUp>
  )
}
import { useRouter } from 'next/router'
import useBreakpoint from '@/hooks/useBreakpoint'
import { cn, Image } from '@heroui/react'
import { t } from 'i18next'
import PlayNowButton from '../common/PlayNowButton'

export default function UnlimitedTours() {
  const { isLess: isLessLg } = useBreakpoint('lg')
  return (
    <section className="py-6 lg:py-8">
      <div className="container mx-auto flex flex-col items-center justify-between gap-4 lg:flex-row lg:gap-6">
        {isLessLg ? (
          <ForSmallScreens isLessLg={isLessLg} />
        ) : (
          <ForLargeScreens />
        )}
      </div>
    </section>
  )
}

const ForLargeScreens = () => {
  return (
    <>
      <SectionImage />
      <div className="flex-center flex-col gap-10 text-center lg:max-w-[47%]">
        <div className="flex-center flex-col gap-5 lg:gap-10">
          <Title />
          <Paragraph />
        </div>
        <PlayNowButton />
      </div>
    </>
  )
}

const ForSmallScreens = ({ isLessLg }) => {
  return (
    <>
      <Title />
      <SectionImage />
      <Paragraph />
      <PlayNowButton
        size={isLessLg ? 'md' : 'lg'}
        classNames={cn(isLessLg ? 'w-40 text-base xl:w-52' : '')}
      />
    </>
  )
}

const SectionImage = () => {
  return (
    <div className="w-full lg:max-w-[50%]">
      <Image
        src="/images/new-homepage/large-pics/Unlimited Tournaments.webp"
        alt="home tournaments"
        className="w-full object-cover"
        classNames={{
          wrapper: '!max-w-full',
        }}
      />
    </div>
  )
}

const Title = () => {
  const { locale } = useRouter()
  const isAr = locale === 'ar-SA'
  return (
    <h3
      className={cn(
        'font-display flex flex-col text-center text-xl font-bold capitalize tracking-wider md:text-3xl lg:text-5xl lg:tracking-normal',
        isAr && 'flex-col-reverse',
      )}
    >
      <span>{t('Unlimited')}</span>
      <span>{t('Instant Competitions')}</span>
    </h3>
  )
}

const Paragraph = () => {
  return (
    <p className="text-jacarta-500 dark:text-jacarta-200 text-center text-sm lg:text-[18px]">
      {t(
        'Experience the thrill of unlimited instant competitions, where the excitement never ends and huge prizes await! Simply connect your game and let the gaming adventures begin!',
      )}
    </p>
  )
}

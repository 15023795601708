// import { useSession } from 'next-auth/react';
import { useEffect } from 'react'
import FairPlay from '@/components/home/sections/FairPlay'
import Hero from '@/components/home/sections/Hero'
import Partners from '@/components/home/sections/Partners'
import PaymentMethods from '@/components/home/sections/PaymentMethods'
import StopScrolling from '@/components/home/sections/StopScrolling'
import TopWinners from '@/components/home/sections/TopWinners'
import UnlimitedTours from '@/components/home/sections/UnlimitedTours'
import { cn } from '@heroui/react'
import { useTheme } from 'next-themes'
import { useTranslation } from 'react-i18next'

import HomeInvestors from '../components/home/sections/HomeInvestors'
import Meta from '../components/Meta'
import { useScrollPositionStore } from '../store/useScrollPosition'

export default function Home() {
  const { t } = useTranslation()
  // const { status } = useSession();
  const { resolvedTheme: theme } = useTheme()
  const { scrollPosition, setPosition } = useScrollPositionStore()

  useEffect(() => {
    window.scrollTo(0, scrollPosition)
    const handleScrollPos = () => {
      setPosition(window.scrollY)
    }
    window.addEventListener('scroll', handleScrollPos)
    return () => {
      window.removeEventListener('scroll', handleScrollPos)
    }
  })

  // if (status === 'loading') return <HomeLoader />;

  return (
    <>
      <Meta title={t('Gamerg_Home_Title') as string} />

      <div
        className={cn(
          theme === 'light'
            ? 'even:*:bg-light-base'
            : 'odd:*:dark:bg-jacarta-800',
        )}
      >
        <Hero />
        <Partners />
        <UnlimitedTours />
        <TopWinners />
        <FairPlay />
        <PaymentMethods />
        <StopScrolling />
        <HomeInvestors />
      </div>
    </>
  )
}

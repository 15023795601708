import { t } from 'i18next'

export const hero_games_logos = [
  {
    img: '/images/new-homepage/games-logos/League of Legends.png',
    name: 'League of Legends',
  },
  { img: '/images/new-homepage/games-logos/Valorant.png', name: 'Valorant' },
  { img: '/images/new-homepage/games-logos/Fortnite.png', name: 'Fortnite' },
  { img: '/images/new-homepage/games-logos/Dota.png', name: 'DotA' },
  {
    img: '/images/new-homepage/games-logos/Brawl Stars.png',
    name: 'Brawl Stars',
  },
]

export const partners_logos = {
  light: [
    {
      img: '/images/new-homepage/sponsors/Zain-light.webp',
      name: t('Strategic Partners'),
    },
    {
      img: '/images/new-homepage/sponsors/Invest Bank-light.webp',
      name: t('Gold Partners'),
    },
  ],
  dark: [
    {
      img: '/images/new-homepage/sponsors/Zain-dark.webp',
      name: t('Strategic Partners'),
    },
    {
      img: '/images/new-homepage/sponsors/Invest Bank-dark.webp',
      name: t('Gold Partners'),
    },
  ],
}

export const paymentMethods = {
  light: [
    {
      image: '/images/new-homepage/payments/Visa-light.webp',
      alt: 'visa',
    },
    {
      image: '/images/new-homepage/payments/Apple Pay-light.webp',
      alt: 'apple pay',
    },
    {
      image: '/images/new-homepage/payments/Paypal-light.webp',
      alt: 'paypal',
    },

    {
      image: '/images/new-homepage/payments/Zain Cash-light.webp',
      alt: 'zain cash',
    },
    {
      image: '/images/new-homepage/payments/STC-light.webp',
      alt: 'stc',
    },
    {
      image: '/images/new-homepage/payments/Fawry-light.webp',
      alt: 'fawry',
    },
  ],

  dark: [
    {
      image: '/images/new-homepage/payments/Visa-dark.webp',
      alt: 'visa',
    },
    {
      image: '/images/new-homepage/payments/Apple Pay-dark.webp',
      alt: 'apple pay',
    },
    {
      image: '/images/new-homepage/payments/Paypal-dark.webp',
      alt: 'paypal',
    },

    {
      image: '/images/new-homepage/payments/Zain Cash-dark.webp',
      alt: 'zain cash',
    },
    {
      image: '/images/new-homepage/payments/STC-dark.webp',
      alt: 'stc',
    },
    {
      image: '/images/new-homepage/payments/Fawry-dark.webp',
      alt: 'fawry',
    },
  ],
}

export const homeSectionTitleClasses =
  'text-center font-display text-xl md:text-3xl lg:text-5xl capitalize mb-5'

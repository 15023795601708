import { Avatar, Card, CardBody } from '@heroui/react'
import { TopWinner } from '@gg/shared/types'
import { formatNumber } from '@gg/shared/utils/helpers'
import RankTooltip from '@/components/RankTooltip'
const TopWinnerCard: React.FC<{ user: TopWinner }> = ({ user }) => {
  const { username, total, profile_pic, userRank } = user

  return (
    <Card
      className="dark:bg-jacarta-800 overflow-visible border-none"
      shadow="sm"
      radius="sm"
      dir="ltr"
    >
      <CardBody className="relative flex flex-col items-center justify-center gap-4 overflow-visible p-3 pt-12">
        <Avatar
          src={profile_pic}
          className="absolute -top-1 start-1/2 h-20 w-20 -translate-x-1/2 -translate-y-1/2 rounded-md shadow-sm"
          color="success"
        />

        <div className="flex flex-col items-center gap-2">
          <div className="flex-center w-full flex-wrap gap-1">
            <h3 className="text-base font-bold">{username}</h3>

            <RankTooltip userRank={userRank} />
          </div>

          <span className="text-green-success text-3xl font-bold">
            {formatNumber(total, {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            })}
          </span>
        </div>
      </CardBody>
    </Card>
  )
}

export default TopWinnerCard

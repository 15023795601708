import { cn, Image } from '@heroui/react'
import { t } from 'i18next'
import { useTheme } from 'next-themes'
import { homeSectionTitleClasses } from '../constants'

function FairPlay() {
  const { resolvedTheme: theme } = useTheme()
  return (
    <section className="py-8">
      <div className="container mx-auto">
        <h3 className={cn(homeSectionTitleClasses, 'mb-10')}>
          {t('FairPlay Technology')}
        </h3>
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2 lg:gap-20">
          <FairPlayCard
            title={t('Skill Based Matchmaking')}
            description={t('SBMM_description')}
            image={`/images/new-homepage/large-pics/SBMM-${theme}.webp`}
          />
          <FairPlayCard
            title={t('Fair Play Anti Cheat')}
            description={t('Anticheat_description')}
            image={`/images/new-homepage/large-pics/Anticheat-${theme}.webp`}
          />
        </div>
      </div>
    </section>
  )
}

export default FairPlay

const FairPlayCard = ({ title, description, image }) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <Image
        src={image}
        alt={title}
        className="opacity-1 w-full max-w-[500px] object-cover"
      />
      <h4 className="font-display mt-3 text-center text-lg font-bold tracking-widest lg:text-3xl lg:tracking-normal">
        {title}
      </h4>
      <p className="text-center text-sm lg:text-lg">{description}</p>
    </div>
  )
}

import { create } from 'zustand'

interface ScrollPositionState {
  scrollPosition: number
  setPosition: (scrollPosition: number) => void
}

export const useScrollPositionStore = create<ScrollPositionState>()((set) => ({
  scrollPosition: 0,
  setPosition: (scrollPosition) => set(() => ({ scrollPosition })),
}))

import React from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useBreakpoint from '@/hooks/useBreakpoint'
import { cn } from '@heroui/react'
import { useTranslation } from 'react-i18next'
import PlayNowButton from '../common/PlayNowButton'

function StopScrolling() {
  const { t } = useTranslation()
  const { isLess: isLessLg } = useBreakpoint('lg')
  const { locale } = useRouter()
  const isAr = locale === 'ar-SA'
  return (
    <div className="relative flex min-h-[160px] w-full items-center justify-center overflow-hidden sm:min-h-[300px] lg:min-h-[500px] xl:min-h-[600px]">
      <div
        className="absolute inset-0 bottom-0 left-0 right-0 top-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage:
            'url("/images/new-homepage/backgrounds/Background Image Bottom.webp")',
        }}
      />

      <div className="relative z-10 flex min-h-[130px] flex-col items-center justify-between sm:min-h-[250px] lg:min-h-[450px] lg:pb-14 xl:min-h-[550px]">
        <h2 className="font-display text-center text-lg font-bold text-white md:text-3xl lg:text-6xl">
          {!isAr && (
            <>
              {t('Stop Scrolling')}
              <br />
            </>
          )}
          <span style={{ fontSize: 'calc(100% - 25%)' }}>
            {t('Start_Playing')}
          </span>
        </h2>
        <PlayNowButton
          size={isLessLg ? 'md' : 'lg'}
          classNames={cn(isLessLg ? 'w-40 text-base xl:w-52' : '')}
        />
      </div>
    </div>
  )
}

export default StopScrolling

import { Image } from '@heroui/react'
import { useTheme } from 'next-themes'
import { paymentMethods } from '../constants'

export default function PaymentMethods() {
  const { resolvedTheme: theme } = useTheme()

  return (
    <section className="py-10">
      <div className="container mx-auto">
        <div className="flex w-full flex-wrap items-center justify-center gap-5 lg:justify-between lg:gap-0">
          {paymentMethods[theme as 'light' | 'dark']?.map((method) => (
            <Image
              key={method.alt}
              src={method.image}
              alt={method.alt}
              className="w-[100px] object-cover md:w-[140px] lg:w-[170px]"
            />
          ))}
        </div>
      </div>
    </section>
  )
}

import Link from 'next/link'
import { Button } from '@heroui/react'
import { t } from 'i18next'
import { useSession } from 'next-auth/react'
import useHasLiveTours from '@gg/api/query/play/useHasLiveTours'
import { useAppSelector } from '@/redux/counterSlice'

export default function PlayNowButton({ classNames = '', size = 'lg' }) {
  const { status } = useSession()
  const { liveKey } = useAppSelector((state) => state.counter)
  const { hasLive } = useHasLiveTours(liveKey)

  return (
    <Link
      href={
        status === 'authenticated'
          ? `/dashboard${hasLive ? '?t=live' : ''}`
          : '/login'
      }
    >
      <Button
        radius="full"
        size={size ?? 'lg'}
        className={`hover:bg-accent-dark w-60 cursor-pointer gap-3 bg-accent text-lg font-semibold text-white shadow-md transition-all hover:-translate-y-1 xl:w-72 ${classNames}`}
      >
        <span>{t('Play Now')}</span>
        <span className="animate-play-icon">🕹️</span>
      </Button>
    </Link>
  )
}

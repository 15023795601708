import Link from 'next/link'
import { Card, CardBody } from '@heroui/react'
import Flag from 'react-world-flags'
import { TopWinner } from '@gg/shared/types'
import { UserImage } from '../../dashboard/live/userAvatar'
import { formatNumber } from '@gg/shared/utils/helpers'

function TopWinnerMobileCard({ user }: { user: TopWinner }) {
  return (
    <Card
      className="border-jacarta-200 dark:border-jacarta-600 w-full rounded-md border"
      dir="ltr"
    >
      <CardBody className="flex w-full flex-row items-center gap-2 p-1.5">
        <Link
          className="flex w-max flex-1 items-center gap-2"
          href={`/u/${user?.username}`}
        >
          <UserImage user={user} hasBorder className="rounded-md" />

          <div className="flex flex-col items-start justify-center -space-y-1">
            <span className="font-display text-medium">{user?.username}</span>
            {/* <Flag
              code={user?.country_code}
              style={{
                width: '24px',
                height: '24px',
              }}
            /> */}
          </div>
        </Link>

        <span className="text-green-success font-display me-3 text-lg font-bold">
          {formatNumber(user.total, {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </CardBody>
    </Card>
  )
}

export default TopWinnerMobileCard

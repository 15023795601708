import DiscordButton from '@/components/navbar/DiscordButton'
import useBreakpoint from '@/hooks/useBreakpoint'
import { cn, Spinner } from '@heroui/react'
import { t } from 'i18next'
import TopWinnerCard from '../cards/TopWinnerCard'
import TopWinnerMobileCard from '../cards/TopWinnerMobileCard'
import { homeSectionTitleClasses } from '../constants'
import useTopEarners from '@gg/api/query/useTopEarners'

function TopWinners() {
  const { data, isPending } = useTopEarners()
  const { isLess: isLessSm } = useBreakpoint('sm')

  return (
    <section className="py-6">
      <div className="container mx-auto">
        <h3 className={cn(homeSectionTitleClasses, 'sm:mb-14')}>
          {t('Top GamerG Winners')}
        </h3>
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-3 sm:gap-y-14 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:gap-5">
          {isPending ? (
            <Spinner />
          ) : isLessSm ? (
            data
              ?.slice(0, 4)
              .map((winner) => (
                <TopWinnerMobileCard key={winner.user_id} user={winner} />
              ))
          ) : (
            data?.map((winner) => (
              <TopWinnerCard key={winner.user_id} user={winner} />
            ))
          )}
        </div>
        <div className="flex-center mt-4 md:mt-6">
          <DiscordButton />
        </div>
      </div>
    </section>
  )
}

export default TopWinners

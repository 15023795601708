import { useQuery } from '@tanstack/react-query'
import API from '../../api'

const useTotalWinnings = () =>
  useQuery({
    queryKey: ['home-total-winnings'],
    queryFn: () =>
      API.get<{ data: string }>('extra/totalWinnings', {
        requiresAuth: false,
      }),
  })

export default useTotalWinnings

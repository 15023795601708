import React from 'react'
import { Image } from '@heroui/react'
import { t } from 'i18next'

export default function HomeInvestors() {
  return (
    <section className="!bg-jacarta-800 px-2 py-6 lg:p-12">
      <h3 className="font-display text-center text-xl capitalize text-white lg:text-4xl">
        {t('Backed by')}
      </h3>
      <div className="flex-center container mx-auto w-full gap-4 pt-6 lg:gap-8">
        {investorsData.map((data, index) => (
          <InvestorCard key={index} data={data} />
        ))}
      </div>
    </section>
  )
}

const InvestorCard = ({ data }) => (
  <div className={`flex-center`}>
    <Image src={data.image} alt={data.name} className="md:mx-auto md:w-[50%]" />
  </div>
)

const investorsData = [
  {
    name: 'flat labs',
    image: '/images/investors/flat-labs.png',
    // description: t('flat_labs_description'),
  },
  {
    name: 'march holding',
    image: '/images/investors/march-holding.png',
    // description: t('march_holding_description'),
  },
  {
    name: 'propeller',
    image: '/images/investors/propeller.png',
    // description: t('propeller_description'),
  },
]

import { Image } from '@heroui/react'
import { useTheme } from 'next-themes'
import { partners_logos } from '../constants'

export default function Partners() {
  const { resolvedTheme: theme } = useTheme()

  return (
    <section className="border-jacarta-300 dark:border-jacarta-600 border-t-2">
      <div
        className="container mx-auto flex flex-col items-center justify-around gap-2 py-4 sm:flex-row"
        dir="ltr"
      >
        {partners_logos[theme as 'light' | 'dark']?.map((logo, index) => (
          <div
            className="font-display flex w-full items-center justify-between sm:w-auto sm:flex-col"
            key={index}
          >
            <h3 className="text-base font-semibold sm:text-lg lg:text-xl">
              {logo.name}
            </h3>
            <Image src={logo.img} alt={logo.name} className="w-[200px]" />
          </div>
        ))}
      </div>
    </section>
  )
}
